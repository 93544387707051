import { Amplify } from 'aws-amplify';
import Mixpanel from 'mixpanel-browser';
import { useEffect, useState } from 'react';
import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module';
import { Route, Routes, useLocation } from 'react-router-dom';
import WebFont from 'webfontloader';
import './App.scss';
import './AppMobile.scss';
import './AppSmallerScreen.scss';
// import { awsExports } from './aws-exports';
import { IntercomProvider } from 'react-use-intercom';
import { SignupDataProvider } from 'src/pages/register/signUpDataProvider';
import { awsExports } from './aws-exports';
import AboutUsPage from './pages/AboutPage/AboutUsPage';
import CashfreeFailure from './pages/Cashfree/failure';
import FlexxPayCashfreeFailure from './pages/Cashfree/flexxpay_fail';
import FlexxPayCashfreeSuccess from './pages/Cashfree/flexxpay_success';
import CashfreeSuccess from './pages/Cashfree/success';
import VKYCFailure from './pages/Cashfree/vkyc_failure';
import VKYCSuccess from './pages/Cashfree/vkyc_success';
import DeleteAccount from './pages/DeleteAccount/delete';
import Download from './pages/Download/Download';
import EarlyAccess from './pages/EarlyAccess/EarlyAccess';
import EarlyAccessWeb from './pages/EarlyAccess/EarlyAccessWeb';
import ForBusinessesPage from './pages/ForBusinessesPage/ForBusinessesPage';
import LandingPage from './pages/LandingPage/LandingPage';
import GifsPage from './pages/GifsPage/GifsPage';
import LegalDocument from './pages/LegalDocument/LegalDocument';
import FAQPage from './pages/Resources/FAQPage/FAQPage';
import ForDevelopersPage from './pages/Resources/ForDevelopersPage/ForDevelopersPage';
import SavingsClub from './pages/SavingsClub';
import SavingsProgrammeEnterDetails from './pages/SuryodayLanding/JoinSavingsProgramme/SavingsProgrammeEnterDetails';
import SavingsProgrammeEnterOTP from './pages/SuryodayLanding/JoinSavingsProgramme/SavingsProgrammeEnterOTP';
import SavingsProgrammeEnterPin from './pages/SuryodayLanding/JoinSavingsProgramme/SavingsProgrammeEnterPin';
import SavingsProgrammeFinished from './pages/SuryodayLanding/JoinSavingsProgramme/SavingsProgrammeFinished';
import { SignupDataProvider as SuryodaySignupDataProvider } from './pages/SuryodayLanding/JoinSavingsProgramme/SignupDataProvider';
import SuryodayLandingPage from './pages/SuryodayLanding/SuryodayLandingPage';
import RegisterFinished from './pages/register/complete';
import RegisterEnterOTP from './pages/register/otp';
import RegisterEnterDetails from './pages/register/personals';
import RegisterEnterPin from './pages/register/pin';
import ContactUsModal from './sharedComponents/ContactUsModal/ContactUsModal';
import DownloadAppModal from './sharedComponents/DownloadAppModal/DownloadAppModal';
import Footer from './sharedComponents/Footer/Footer';
import { FullScreenLoader } from './sharedComponents/FullScreenLoader/FullScreenLoader';
import RegisterModal from './sharedComponents/RegisterModal/RegisterModal';
import TopNavBar from './sharedComponents/TopNavBar/TopNavBar';
import { Bounce, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
//ve
//FRONTLOAD FONTS (apparently to improve performance):
WebFont.load({
  custom: {
    families: ['Telegraf-UltraLight', 'Telegraf-Regular', 'Saathi-UltraBold'],
  },
});

// initialse mixpanel
Mixpanel.init(process.env.REACT_APP_MIXPANEL_TRACKING);

// initialise google tag manager
const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TRACKING,
};
TagManager.initialize(tagManagerArgs);

// initialise facebook meta pixel
const advancedMatching = { em: '' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};
// @ts-ignore
ReactPixel.init(process.env.REACT_APP_FB_TRACKING, advancedMatching, options);

Amplify.configure(awsExports);

export default function App() {
  let location = useLocation();
  const [gifsPage, setGifsPage] = useState(false);
  let [showRegOrDLModalBool, setShowRegOrDLModalBool] = useState(false); //<-- boolean to show the modal or not (registration or download depends on app's status (live or not live))
  let [showContactUsModalBool, setShowContactUsModalBool] = useState(false); //<-- boolean to show the contact us modal or not
  let [showDeleteModalBool, setShowDeleteModalBool] = useState(false);
  // showContactUsModalBool = true; ////

  let [fullScreenLoaderActiveBoolean, setFullScreenLoaderActiveBoolean] = useState(false);

  // DETECT SCREEN SIZE FOR WINDOW-SIZE-DEPENDANT LOGICAL RENDERING:
  let [deviceType, setDeviceType] = useState<'desktop' | 'mobile'>(null); //<-- 'desktop' OR 'mobile'
  useEffect(() => {
    const updateDimensions = () => {
      setDeviceType(window.innerWidth >= 1150 ? 'desktop' : 'mobile');
    };
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  //Scroll to top of page when navigating (except for hash routing):
  useEffect(() => {
    if (window.location.href.includes("gifs")) {
      setGifsPage(true);
    } else {
      setGifsPage(false);
    }
    if (!location.hash) window.scrollTo(0, 0);
    ReactPixel.pageView();
    Mixpanel.track('pageview', { url: location.pathname });
  }, [location]);

  const projectLiveBoolean = process.env.REACT_APP_PROJECT_LIVE_BOOLEAN === 'true';

  return (
    <IntercomProvider appId='fj41tuh9' autoBoot>
      <div className='App'>
        <>
          <TopNavBar
            pageLocation={location.pathname}
            deviceType={deviceType}
            setShowRegOrDLModalBool={setShowRegOrDLModalBool}
            projectLiveBoolean={projectLiveBoolean}
          />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
            transition={Bounce}
          />

          <Routes>
            {/* LANDING PAGE: */}
            <Route
              index
              element={
                <LandingPage
                  deviceType={deviceType}
                  projectLiveBoolean={projectLiveBoolean}
                  setShowRegOrDLModalBool={setShowRegOrDLModalBool}
                  setShowContactUsModalBool={setShowContactUsModalBool}
                />
              }
            />
            <Route path='/gifs' element={<GifsPage deviceType={deviceType} />} />
            <Route path='/Suryoday' element={<SuryodaySignupDataProvider />}>
              <Route index element={<SuryodayLandingPage deviceType={deviceType} />} />
              <Route path='SavingsProgramme'>
                <Route
                  path='EnterDetails'
                  element={<SavingsProgrammeEnterDetails deviceType={deviceType} />}
                />
                <Route
                  path='EnterOTP'
                  element={<SavingsProgrammeEnterOTP deviceType={deviceType} />}
                />
                <Route
                  path='EnterPin'
                  element={<SavingsProgrammeEnterPin deviceType={deviceType} />}
                />
                <Route
                  path='Finished'
                  element={<SavingsProgrammeFinished deviceType={deviceType} />}
                />
              </Route>
            </Route>
            <Route path='/register' element={<SignupDataProvider />}>
              <Route index element={<RegisterEnterDetails deviceType={deviceType} />} />
              <Route path='EnterDetails' element={<RegisterEnterDetails deviceType={deviceType} />} />
              <Route path='EnterOTP' element={<RegisterEnterOTP deviceType={deviceType} />} />
              <Route path='EnterPin' element={<RegisterEnterPin deviceType={deviceType} />} />
              <Route path='Finished' element={<RegisterFinished deviceType={deviceType} />} />
            </Route>

            {/* For Businesses: */}
            <Route
              path='/ForBusinesses'
              element={
                <ForBusinessesPage
                  setShowRegOrDLModalBool={setShowRegOrDLModalBool}
                  setShowContactUsModalBool={setShowContactUsModalBool}
                  deviceType={deviceType}
                />
              }
            />

            {/* 'ABOUT' PAGES: */}
            <Route
              path='AboutUs'
              element={
                <AboutUsPage
                  setShowRegOrDLModalBool={setShowRegOrDLModalBool}
                  setShowContactUsModalBool={setShowContactUsModalBool}
                />
              }
            />

            {/* 'Savings Club' PAGES: */}
            <Route path='/savings-club' element={<SavingsClub />} />

            {/* 'Cashfree App Success' PAGES: */}
            <Route path='/cfsub/success' element={<CashfreeSuccess />} />

            {/* 'Cashfree App Failure' PAGES: */}
            <Route path='/cfsub/failure' element={<CashfreeFailure />} />

            {/* 'vKYC App Success' PAGES: */}
            <Route path='/vkyc/success' element={<VKYCSuccess />} />

            {/* 'vKYC App Failure' PAGES: */}
            <Route path='/vkyc/failure' element={<VKYCFailure />} />

            {/* 'Cashfree Flexxpay Success' PAGES: */}
            <Route path='/cfsub/flexxpaysuccess' element={<FlexxPayCashfreeSuccess />} />

            {/* 'Cashfree Flexxpay Failure' PAGES: */}
            <Route path='/cfsub/flexxpayfailure' element={<FlexxPayCashfreeFailure />} />

            {/* 'RESOURCES' PAGES: */}
            <Route path='Resources'>
              {/* For Developers: */}
              <Route path='ForDevelopers' element={<ForDevelopersPage />} />
              <Route
                path='FAQ'
                element={
                  <FAQPage
                    deviceType={deviceType}
                    setShowRegOrDLModalBool={setShowRegOrDLModalBool}
                    setShowContactUsModalBool={setShowContactUsModalBool}
                  />
                }
              />
            </Route>

            <Route path='app' element={<Download />}></Route>
            <Route path='earlyaccess' element={<EarlyAccess />}></Route>
            <Route path='earlyaccessweb' element={<EarlyAccessWeb />}></Route>

            <Route
              path='delete'
              element={
                // @ts-ignore
                <DeleteAccount showDeleteModalBool={setShowDeleteModalBool} />
              }
            />

            {/* LEGAL DOCUMENTS PULLED FROM DB */}
            <Route
              path='/LegalDocs/:DocumentName'
              element={
                <LegalDocument
                  DocumentName={location.pathname.split('/LegalDocs/')[1]}
                  setFullScreenLoaderActiveBoolean={setFullScreenLoaderActiveBoolean}
                  setShowRegOrDLModalBool={setShowRegOrDLModalBool}
                />
              }
            />
          </Routes>

          {/* THE REGISTRATION MODAL OR DOWNLOAD APP MODAL (ONCE PROJECT LIVE): */}
          {showRegOrDLModalBool &&
            (projectLiveBoolean ? (
              <DownloadAppModal
                setShowRegOrDLModalBool={setShowRegOrDLModalBool}
                // @ts-ignore
                setShowContactUsModalBool={setShowContactUsModalBool}
                setFullScreenLoaderActiveBoolean={setFullScreenLoaderActiveBoolean}
              />
            ) : (
              <RegisterModal
                pageLocation={location.pathname}
                setShowRegOrDLModalBool={setShowRegOrDLModalBool}
                setFullScreenLoaderActiveBoolean={setFullScreenLoaderActiveBoolean}
              />
            ))}

          {/* CONTACT US MODAL: */}
          {showContactUsModalBool && (
            <ContactUsModal setShowContactUsModalBool={setShowContactUsModalBool} />
          )}

          {/* FULL SCREEN LOADER (for API calls): */}
          {fullScreenLoaderActiveBoolean && <FullScreenLoader />}

          <Footer
            pageLocation={location.pathname}
            setShowContactUsModalBool={setShowContactUsModalBool}
          />
        </>
      </div>
    </IntercomProvider>
  );
}

import akbarTravels from '../../../../assets/icons/akbarTravels.svg';
import holidayTribe from '../../../../assets/icons/holidayTribe.svg';
import candere from '../../../../assets/icons/candere.svg';
import shoppersStop from '../../../../assets/icons/shoppersStop.svg';
import acer from '../../../../assets/icons/acer.svg';
import uniqlo from '../../../../assets/icons/uniqlo.svg';
import pepperfry from '../../../../assets/icons/pepperfry.svg';
import nykaa from '../../../../assets/icons/nykaa.svg';
import CategoryCards from './CategoryCards';

const EarnRewardsOn = ({ deviceType, merchantLogosSectionRef }) => {
  // const merchantImages = [
  //   akbarTravels,
  //   candere,
  //   shoppersStop,
  //   holidayTribe,
  //   uniqlo,
  //   pepperfry,
  //   nykaa,
  //   acer,
  // ];

  const merchantImages = [
    akbarTravels,
    holidayTribe
  ];

  return (
    <div className='pt-14'>
      <h5 className='mb-4 px-5 text-center text-2xl sm:text-5xl'>Plan, Save, Holiday Better</h5>
      <p className='mb-16 text-center text-base sm:text-2xl m-auto w-full max-w-[920px]'>
        Earn up to 7.75% annual interest on your digital savings account and get up to 5% rewards as cashback, when you complete your booking with our travel partners
      </p>
      <CategoryCards deviceType={deviceType} />
      
      <h5 className='mb-16 px-5 text-center text-2xl sm:text-4xl' id='merchants'>Merchant Reward Partners</h5>
      <div ref={merchantLogosSectionRef} className='m-auto max-w-[1200px] px-8 md:px-16'>
        <div className='grid grid-cols-2 gap-11 sm:grid-cols-12 sm:gap-[72px]'>
          <div className='hidden sm:flex sm:col-span-1'></div>
          {merchantImages.map((img, i) => {
            return <div className='col-span-2 sm:col-span-5 flex justify-center'>
              <img src={img} className='w-60 max-h-12' key={i} />
            </div>;
          })}
          <div className='hidden sm:flex sm:col-span-1'></div>
        </div>
      </div>
    </div>
  );
};

export default EarnRewardsOn;

import { useEffect, useRef } from 'react';
import SavingsProgrammeEnterDetails from 'src/pages/SuryodayLanding/JoinSavingsProgramme/SavingsProgrammeEnterDetails';
import { classify } from 'src/utils';
// import EarnRewardsOnSection from './components/EarnRewardsOnSection/EarnRewardsOnSection';
import HeroSection from './components/HeroSection/HeroSectionGIFS';
import mixpanel from 'mixpanel-browser';
import Form from './components/Form/Form';
import phone from '../../assets/images/phone.png';
import rewardsBackground from '../../assets/images/rewardsBackground.jpg';
import video from '../../assets/videos/flow.mp4';
import './GifsPage.scss';

const GifsPage = ({ deviceType }: { deviceType: 'desktop' | 'mobile' }) => {
    const merchantLogosSectionRef = useRef(null);

    const scrollDownToMerchantLogos = () => {
        merchantLogosSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        mixpanel.track("pageview_sbl_landing");
    }, [])


    return (
        <>
            <div
                id='gifs-page'
                style={{
                    overflowX: "hidden"
                }}
                className={classify([
                    'mx-auto [background:linear-gradient(180deg,#FFF_7.41%,rgba(255,255,255,0.50)100%),radial-gradient(98.33%_50%_at_50%_50%,#FBA4DA_0%,#99C7FB_100%)] lg:h-screen overflow-x-hidden overflow-y-hidden',
                ])}
            >
                <HeroSection
                    deviceType={deviceType}
                    scrollDownToMerchantLogos={scrollDownToMerchantLogos}
                />
            </div>
            <div id='partnership-section' className={classify(['mx-auto grid grid-cols-12','xs:mt-4 md:mt-10'])}>
                <div className="col-span-12 md:col-span-1"></div>
                <div className="col-span-12 md:col-span-5 xs:px-5 md:px-3 flex flex-col justify-center">
                    <span className={classify(['xs:mt-4 md:mt-10', 'sm:text-[1.8rem] lg:text-[1.8rem] font-telegrafUltraBold !leading-[1.125]', 'xs:text-[1.5rem] text-center md:text-start'])}>
                        About the Partnership
                    </span>
                    <p className='xs:mt-4 md:mt-10 w-[90%] text-[1.3rem] md:text-justify xs:text-left xs:text-[1.2rem] md:ml-0 xs:mx-auto'>
                        According to World Bank data, over 75% of adults in India have a bank account, but less than 25% have saved money in the past year and less than 15% have saved money in a financial institution. These numbers are even lower for youth (aged 15-24) and women, further emphasizing the need for initiatives that promote active savings and financial resilience building, especially among low-income and underserved communities.
                    </p>
                    <p className='xs:mt-4 md:mt-10 w-[90%] text-[1.3rem] md:text-justify xs:text-left xs:text-[1.2rem] md:ml-0 xs:mx-auto'>
                        Using artificial intelligence, behavioral science and individual and group incentives, the partnership will promote financial security by rewarding SSFB’s new and existing clients to achieve their financial goals with confidence. The initiative is expected to surface business-relevant insights about mechanisms to increase account usage, savings rate and financial goal achievement.
                    </p>
                </div>
                <div className="col-span-12 md:col-span-6 right-side xs:mt-10 md:mt-0">
                    <div className='rightSide'>
                        <div className='device'>
                            <video loop autoPlay muted playsInline src={video}></video>
                            <img src={phone} alt='Saathi app explainer video' />
                        </div>
                    </div>
                </div>
            </div>

            <div id='register' className='md:mt-0 xs:mt-10'>
                <Form deviceType={deviceType} />
                {/* <SavingsProgrammeEnterDetails deviceType={deviceType} /> */}
            </div>
        </>
    );
};

export default GifsPage;

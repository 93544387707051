import phonePhoto from '../../../../assets/images/phone2.png'
import rewards from '../../../../assets/images/rewards_icon.svg';
import suryodayLogo from '../../../../assets/icons/suryodayLogo.svg'
import dwmLogo from '../../../../assets/icons/dwm_logo.png'
// import banner from '../../../../assets/images/akb_banner_phone_section.svg';
import banner from '../../../../assets/images/dwm_banner.png';
import { classify } from 'src/utils';
import { Heading } from 'src/sharedComponents/Typography/Heading';
const HeroSectionGIFS = ({ deviceType, scrollDownToMerchantLogos }) => {
    return (
        <div className='h-full mt-20 py-10 lg:py-20 overflow-hidden'>
            <div className="grid grid-cols-12">
                <div className="col-span-1"></div>
                <div className='col-span-12 text-center md:text-start md:col-span-5 flex flex-col overflow-hidden'>
                    <h1 className={classify(['mt-[0px]', 'sm:text-[3.5rem] lg:text-[3.5rem] text-black font-telegrafUltraBold', 'xs:text-[42px]', 'text-center md:text-left overflow-hidden'])}>
                        Boosting 
                    </h1>
                    <h1 className={classify(["font-bold text-transparent bg-clip-text", 'mt-[10px]', 'sm:text-[3.5rem] lg:text-[3.5rem]', 'xs:text-[42px] text-center md:text-start overflow-hidden'])}
                        style={{
                            backgroundImage: 'linear-gradient(90deg, #9a00e6, #e600a5)',
                        }}
                    >
                        Financial Resilience
                    </h1>
                    {/* <Heading className={classify(['mt-[0px]', 'sm:text-[3.5rem] lg:text-[3.5rem]', 'xs:text-[42px] text-center md:text-start'])}>
                      Bucket Lists
                  </Heading> */}

                    <div className='flex flex-col mt-8 w-full items-center md:items-start gap-1'>
                        <div className='w-full flex items-center md:justify-start justify-center overflow-hidden'>
                            <span className={classify(['mt-[0px]', 'sm:text-[1.5rem] lg:text-[1.5rem] font-telegrafSemiBold !leading-[1.125]', 'xs:text-[1.5rem] overflow-hidden text-center md:text-start'])}>
                                Helping over {'  '}
                            </span>
                            <h4 className={classify(["font-bold text-transparent bg-clip-text", '-mb-[4px] md:-mb-[7px] mx-2', 'sm:text-[1.5rem] lg:text-[1.5rem]', 'xs:text-[1.5rem] text-center md:text-start overflow-hidden'])}
                                style={{
                                    backgroundImage: 'linear-gradient(90deg, #9a00e6, #e600a5)',
                                }}
                            >
                                1 million women
                            </h4>
                            {/* <Heading className={classify(['-mb-[7px] mx-1', 'sm:text-[1.8rem] lg:text-[1.8rem]', 'xs:text-[1.5rem] text-center md:text-start'])}> rewarding</Heading> */}
                        </div>
                        <span className={classify(['mt-[0px] xs:px-5 md:px-0', 'sm:text-[1.5rem] lg:text-[1.5rem] font-telegrafSemiBold !leading-[1.125]', 'xs:text-[1.5rem] text-center md:text-start overflow-hidden'])}>{'  '} break the cycle of living paycheck to paycheck</span>

                        {/* <p className='xs:mt-4 md:mt-10 w-[90%] text-[1.3rem] md:text-justify xs:text-center xs:text-[1.2rem] md:ml-0 xs:mx-auto'>
                            Earn big when you plan your next <br /> adventure & stand a chance to <br />
                            win <strong>Rs.10,000</strong>* towards your next holiday
                        </p> */}

                        <span className={classify(['xs:mt-10 md:mt-10', 'sm:text-[1.5rem] lg:text-[1.5rem] font-telegrafSemiBold !leading-[1.125]', 'xs:text-[1.5rem] text-center md:text-start'])}>
                            Our Partners
                        </span>
                        <div className="flex gap-x-6 xs:mt-2">
                            <img className='xs:w-28 md:w-36' src={suryodayLogo} alt="suryoday-logo" />
                            <img className='xs:w-28 md:w-36' src={dwmLogo} alt="suryoday-logo" />
                        </div>
                        <a
                            href='#register'
                            className='group mt-6 relative flex h-12 w-fit items-center overflow-hidden rounded-3xl bg-gradient-to-r from-purple to-blue px-8 text-lg font-bold text-white md:text-xl lg:text-lg'
                        >
                            <span className='absolute inset-0 bg-gradient-to-r from-blue to-purple opacity-0 transition-opacity group-hover:opacity-100' />
                            <span className='isolate font-telegrafUltraBold'>Learn More</span>
                        </a>
                    </div>
                </div>
                <div className="col-span-12 md:col-span-5 md:mt-0 mt-10">
                    <img className='w-full mx-auto lg:mt-[-30px]' src={banner} alt='banner' />
                </div>
                <div className="col-span-1"></div>
            </div>
        </div>
    );
};
export default HeroSectionGIFS;

import React from 'react'
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InputField from 'src/sharedComponents/Forms/InputField';
import { classify } from 'src/utils';
import Mixpanel from 'mixpanel-browser';
import SignUpPageTemplate from './SignUpPageTemplate';
import { Bounce, toast } from 'react-toastify';
import { createEncouragement, createEncouragementUser } from './utils';


const Form = ({ deviceType }: { deviceType: 'desktop' | 'mobile' }) => {
    const [signupInfo, setSignupInfo] = useState({
        name: "",
        email: "",
        mobile: "",
        organisation: "",
    })
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const navigate = useNavigate();

    const handleAlphaInputChange =
        (field: 'name' | 'organisation') => (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value;
            // Allow only alphabetic characters
            const regex = /^[a-zA-Z ]*$/;
            if (regex.test(value)) {
                setSignupInfo({
                    ...signupInfo,
                    [field]: value,
                });
            }
        };

    const processSubmit = async () => {
        try {
            setIsSubmitting(true);
            const encouragementUser = await createEncouragementUser(signupInfo.name, signupInfo.email);
            if(encouragementUser.given_name && encouragementUser.given_name.length>0) {
                const encouragementMsg = await createEncouragement(
                    {
                        comment : `Organisation: ${signupInfo.organisation}\nMobile: ${signupInfo.mobile}`,
                        encourager_id: encouragementUser.encourager_id,
                        location : "Saathi GIFS",
                        public_goal_id: "bacf798b-1941-4b13-9b1a-1fd7f86d3f19",
                        has_contribution: false,
                    },
                    encouragementUser,
                )

                if(encouragementMsg.createdAt && encouragementMsg.createdAt.length>0) {
                    toast.success("Thank you for your response!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        transition: Bounce,
                    });
                }
                setSignupInfo({
                    name: "",
                    email: "",
                    mobile: "",
                    organisation: "",
                })
                setIsSubmitting(false);
            }
        } catch (error) {
            setIsSubmitting(false);
            setErrorMessage("We are facing some issues from our end, please try again later")
        }
    }
    return (
        <SignUpPageTemplate
            heading='Join the Movement'
            subHeading=''
            onSubmit={processSubmit}
            isSubmitting={isSubmitting}
            deviceType={deviceType}
            errorMessage={errorMessage}
            showBackButton={false}
            fromGifs={true}
        >
            <InputField
                label='Name*'
                autoCapitalize='words'
                autoComplete='given-name'
                pattern="[a-zA-Z ]+"
                autoFocus
                required
                defaultValue={signupInfo.name}
                value={signupInfo.name}
                onChange={handleAlphaInputChange('name')}
            />

            <InputField
                label='Email*'
                type='email'
                autoComplete='email'
                required
                defaultValue={signupInfo.email}
                value={signupInfo.email}
                onChange={(e) => {
                    setSignupInfo({
                        ...signupInfo,
                        email: e.target.value,
                    });
                }}
            />

            <InputField
                label='Mobile Number*'
                type='tel'
                pre='+91'
                autoComplete='tel'
                required
                defaultValue={signupInfo.mobile}
                value={signupInfo.mobile}
                onChange={(e) => {
                    setSignupInfo({
                        ...signupInfo,
                        mobile: e.target.value,
                    });
                }}
            />

            <InputField
                label='Organisation*'
                autoCapitalize='words'
                autoComplete='given-name'
                pattern="[a-zA-Z ]+"
                autoFocus
                required
                defaultValue={signupInfo.organisation}
                value={signupInfo.organisation}
                onChange={handleAlphaInputChange('organisation')}
            />
        </SignUpPageTemplate>
    )
}

export default Form
import { API } from "aws-amplify";
type EncouragementUser = {
    createdAt: string;
    encourager_id: string;
    given_name: string;
    is_verified: boolean;
    updatedAt: string;
};

type Encouragement = {
    comment: string;
    encourager_id: string;
    location: string;
    has_contribution: boolean;
    contribution_details?: any;
    public_goal_id: string;
    createdAt: string;
    updatedAt: string;

    encouragement_user: EncouragementUser;
};

export async function createEncouragementUser(
    name: string,
    email: string,
): Promise<EncouragementUser> {
    let encouragementUserCreated: EncouragementUser;
    try {
        console.log('CREATING ENCOURAGEMENT USER WITH', name);

        await API.post('encouragementAPI', '/encouragement/user/create', {
            body: {
                given_name: name,
                email: email,
                is_verified: true,
            },
        }).then((res) => {
            console.log('RESULT FROM ENCOURAGEMENT USER CREATION API', res);
            encouragementUserCreated = res.success.data;
        });
    } catch (err) {
        console.log('ERROR HITTING CREATE ENCOURAGEMENT USER API', err);
    }
    console.log('ENCOURAGEMENT USER CREATED', encouragementUserCreated);
    return encouragementUserCreated;
}

export async function createEncouragement(
    encouragementInput: {
        comment: string;
        encourager_id: string;
        location: string;
        has_contribution: boolean;
        contribution_details?: {};
        public_goal_id: string;
    },
    encouragementUser: EncouragementUser,
): Promise<Encouragement> {
    console.log('ENCOURAGEMENT DATA INPUTTED FOR NEW ENCOURAGEMENT', encouragementInput);
    let encouragementCreated: Encouragement;

    try {
        await API.post('encouragementAPI', '/encouragement/create', {
            body: encouragementInput,
        }).then((res) => {
            console.log('RESULT FROM HITTING ENCOURAGEMENT CREATION API', res);
            encouragementCreated = res.success.data;
            encouragementCreated.encouragement_user = encouragementUser;
        });
    } catch (err) {
        console.log('ERROR HITTING OUR ENCOURAGEMENT CREATION API', err);
    }

    return encouragementCreated;
}
export interface IdeaCategory {
  category_id: string;
  category_name: string;
  category_description: string;
  idea_id: string;
  merchant_id?: string;
  is_active: boolean;
  image_vertical_url: string;
  image_horizontal_url: string;
  createdAt: string;
  updatedAt: string;
}

export const ideaCategories: IdeaCategory[] = [
  {
    "category_id": "022f5402-e609-4537-93d1-6927a2371037",
    "category_name": "Vaishnodevi",
    "category_description": "Undertake the holy trek to Vaishnodevi, a revered shrine nestled in the Trikuta mountains, offering blessings to countless devotees.",
    "merchant_id": "suryoday",
    "is_active": false,
    "image_vertical_url": "https://communications.saathi.money/suryoday_ideas_bucket/vaishnodevi.png",
    "image_horizontal_url": "https://communications.saathi.money/suryoday_ideas_bucket/vaishnodevi.png",
    "createdAt": "2024-11-11 10:19:10.180638+00",
    "updatedAt": "2024-11-11 10:19:10.180638+00",
    "idea_id": "1731324408260"
  },
  {
    "category_id": "1aed9c40-0e37-427f-b5b9-f43a0ecd87f8",
    "category_name": "Ayodhya",
    "category_description": "Explore Ayodhya, the birthplace of Lord Rama, and experience the deep-rooted history and culture of this ancient city along the banks of the Sarayu River.",
    "merchant_id": "suryoday",
    "is_active": false,
    "image_vertical_url": "https://communications.saathi.money/suryoday_ideas_bucket/Ayodhya.jpg",
    "image_horizontal_url": "https://communications.saathi.money/suryoday_ideas_bucket/Ayodhya.jpg",
    "createdAt": "2024-11-11 10:19:09.665138+00",
    "updatedAt": "2024-11-11 10:19:09.665138+00",
    "idea_id": "1731324408260"
  },
  {
    "category_id": "1e2c89c0-6948-49bb-bc86-b49374282402",
    "category_name": "Jaipur",
    "category_description": "Discover Jaipur’s vibrant palaces, historic forts, and colorful markets—an ideal gateway to Rajasthan’s royal heritage.",
    "merchant_id": "suryoday",
    "is_active": false,
    "image_vertical_url": "https://communications.saathi.money/suryoday_ideas_bucket/jaipur.jpg",
    "image_horizontal_url": "https://communications.saathi.money/suryoday_ideas_bucket/jaipur.jpg",
    "createdAt": "2024-11-11 10:19:10.2518+00",
    "updatedAt": "2024-11-11 10:19:10.2518+00",
    "idea_id": "1731324408260"
  },
  {
    "category_id": "30e4d43c-5f46-406d-baa9-1a9c86c83e0f",
    "category_name": "Kashmir",
    "category_description": "Known as 'Paradise on Earth,' Kashmir boasts majestic mountains, serene lakes, and picturesque gardens.",
    "merchant_id": "suryoday",
    "is_active": false,
    "image_vertical_url": "https://communications.saathi.money/suryoday_ideas_bucket/kashmir.jpg",
    "image_horizontal_url": "https://communications.saathi.money/suryoday_ideas_bucket/kashmir.jpg",
    "createdAt": "2024-11-11 10:19:10.221302+00",
    "updatedAt": "2024-11-11 10:19:10.221302+00",
    "idea_id": "1731324408260"
  },
  {
    "category_id": "413d5ba6-cc0f-4f62-87d5-3407be8ae9ac",
    "category_name": "Jagganath Puri",
    "category_description": "Home to the iconic Jagannath Temple, Puri offers a blend of spirituality and culture. Enjoy the famous Rath Yatra and relax on Puri’s sandy beaches.",
    "merchant_id": "suryoday",
    "is_active": false,
    "image_vertical_url": "https://communications.saathi.money/suryoday_ideas_bucket/jaganath_puri.jpg",
    "image_horizontal_url": "https://communications.saathi.money/suryoday_ideas_bucket/jaganath_puri.jpg",
    "createdAt": "2024-11-11 10:19:10.203457+00",
    "updatedAt": "2024-11-11 10:19:10.203457+00",
    "idea_id": "1731324408260"
  },
  {
    "category_id": "466d9956-49bc-4f5c-bbd1-7f564d3fb3d0",
    "category_name": "Tirupati",
    "category_description": "Visit Tirupati to experience divine serenity at the famous Venkateswara Temple. Nestled in the lush Tirumala hills, it’s a spiritual journey like no other.",
    "merchant_id": "suryoday",
    "is_active": false,
    "image_vertical_url": "https://communications.saathi.money/suryoday_ideas_bucket/Tirumala_090615.jpg",
    "image_horizontal_url": "https://communications.saathi.money/suryoday_ideas_bucket/Tirumala_090615.jpg",
    "createdAt": "2024-11-11 10:19:10.274917+00",
    "updatedAt": "2024-11-11 10:19:10.274917+00",
    "idea_id": "1731324408260"
  },
  {
    "category_id": "533d81b0-e2eb-43c4-aecd-33f2262040ee",
    "category_name": "Ladakh",
    "category_description": "Embark on an adventure in Ladakh’s rugged landscapes, with crystal-clear lakes, towering mountains, and a culture rich in Tibetan influences.",
    "merchant_id": "suryoday",
    "is_active": false,
    "image_vertical_url": "https://communications.saathi.money/suryoday_ideas_bucket/ladhakh.jpg",
    "image_horizontal_url": "https://communications.saathi.money/suryoday_ideas_bucket/ladhakh.jpg",
    "createdAt": "2024-11-11 10:19:10.283306+00",
    "updatedAt": "2024-11-11 10:19:10.283306+00",
    "idea_id": "1731324408260"
  },
  {
    "category_id": "5dfbf2af-6554-45ba-b857-5b8568552442",
    "category_name": "Goa",
    "category_description": "From lively beaches to tranquil churches, Goa offers a blend of cultural charm and vibrant nightlife—a true paradise for relaxation and exploration.",
    "merchant_id": "suryoday",
    "is_active": false,
    "image_vertical_url": "https://communications.saathi.money/suryoday_ideas_bucket/goa.jpg",
    "image_horizontal_url": "https://communications.saathi.money/suryoday_ideas_bucket/goa.jpg",
    "createdAt": "2024-11-11 10:19:10.262928+00",
    "updatedAt": "2024-11-11 10:19:10.262928+00",
    "idea_id": "1731324408260"
  },
  {
    "category_id": "91d255aa-2c0d-4ec7-8a3b-ea4eb047fc98",
    "category_name": "Shirdi",
    "category_description": "Visit Shirdi to experience the divine aura of Sai Baba’s teachings, with millions of devotees gathering here for spiritual peace and blessings.",
    "merchant_id": "suryoday",
    "is_active": false,
    "image_vertical_url": "https://communications.saathi.money/suryoday_ideas_bucket/shirdi.jpeg",
    "image_horizontal_url": "https://communications.saathi.money/suryoday_ideas_bucket/shirdi.jpeg",
    "createdAt": "2024-11-11 10:19:10.208381+00",
    "updatedAt": "2024-11-11 10:19:10.208381+00",
    "idea_id": "1731324408260"
  },
  {
    "category_id": "9925f1a8-0055-4d4e-abde-5e10a1543453",
    "category_name": "Ajmer Sharif",
    "category_description": "Ajmer Sharif, the revered Sufi shrine of Moinuddin Chishti, draws people of all faiths for its powerful sense of peace and devotion.",
    "merchant_id": "suryoday",
    "is_active": false,
    "image_vertical_url": "https://communications.saathi.money/suryoday_ideas_bucket/ajmer.jpg",
    "image_horizontal_url": "https://communications.saathi.money/suryoday_ideas_bucket/ajmer.jpg",
    "createdAt": "2024-11-11 10:19:10.27568+00",
    "updatedAt": "2024-11-11 10:19:10.27568+00",
    "idea_id": "1731324408260"
  },
  {
    "category_id": "664f15a8-a993-47b6-9c93-a0a4518a0e92",
    "category_name": "Kerala",
    "category_description": "Kerala, 'God's Own Country,' is known for its lush backwaters, exotic beaches, and rejuvenating Ayurvedic treatments.",
    "merchant_id": "suryoday",
    "is_active": false,
    "image_vertical_url": "https://communications.saathi.money/suryoday_ideas_bucket/kerela.jpg",
    "image_horizontal_url": "https://communications.saathi.money/suryoday_ideas_bucket/kerela.jpg",
    "createdAt": "2024-11-11 10:19:10.205937+00",
    "updatedAt": "2024-11-11 10:19:10.205937+00",
    "idea_id": "1731324408260"
  },
  {
    "category_id": "90fb5947-8e60-4589-a7ad-fe0dc4bab4ab",
    "category_name": "Spiti Valley",
    "category_description": "Discover Spiti Valley’s stark beauty with its snow-clad mountains, crystal-clear rivers, and ancient monasteries—a Himalayan paradise for nature lovers and adventurers.",
    "merchant_id": "suryoday",
    "is_active": false,
    "image_vertical_url": "https://communications.saathi.money/suryoday_ideas_bucket/spiti_valley.jpg",
    "image_horizontal_url": "https://communications.saathi.money/suryoday_ideas_bucket/spiti_valley.jpg",
    "createdAt": "2024-11-11 10:19:10.193516+00",
    "updatedAt": "2024-11-11 10:19:10.193516+00",
    "idea_id": "1731324408260"
  },
  {
    "category_id": "aa823d24-a549-429a-9b37-e8417dcf42db",
    "category_name": "Char Dham",
    "category_description": "Embark on the Char Dham pilgrimage to visit the four holy sites, offering a journey of spiritual rejuvenation across India’s diverse landscapes.",
    "merchant_id": "suryoday",
    "is_active": false,
    "image_vertical_url": "https://communications.saathi.money/suryoday_ideas_bucket/Char-Dham.jpg",
    "image_horizontal_url": "https://communications.saathi.money/suryoday_ideas_bucket/Char-Dham.jpg",
    "createdAt": "2024-11-11 10:19:09.91576+00",
    "updatedAt": "2024-11-11 10:19:09.91576+00",
    "idea_id": "1731324408260"
  },
  {
    "category_id": "cba3db72-64bf-496c-a334-ecf4d09e0599",
    "category_name": "Trimbakeshvar",
    "category_description": "One of India’s twelve Jyotirlinga sites, Trimbakeshvar is a deeply sacred place located amidst scenic hills, perfect for those seeking peace and devotion.",
    "merchant_id": "suryoday",
    "is_active": false,
    "image_vertical_url": "https://communications.saathi.money/suryoday_ideas_bucket/Trimbakeshwar.jpg",
    "image_horizontal_url": "https://communications.saathi.money/suryoday_ideas_bucket/Trimbakeshwar.jpg",
    "createdAt": "2024-11-11 10:19:10.16981+00",
    "updatedAt": "2024-11-11 10:19:10.16981+00",
    "idea_id": "1731324408260"
  },
  {
    "category_id": "30e4d43c-5f46-406d-baa9-1a9c86c83e0f",
    "category_name": "Kashmir",
    "category_description": "Known as 'Paradise on Earth,' Kashmir boasts majestic mountains, serene lakes, and picturesque gardens.",
    "merchant_id": "suryoday",
    "is_active": false,
    "image_vertical_url": "https://communications.saathi.money/suryoday_ideas_bucket/kashmir.jpg",
    "image_horizontal_url": "https://communications.saathi.money/suryoday_ideas_bucket/kashmir.jpg",
    "createdAt": "2024-11-11 10:19:10.221302+00",
    "updatedAt": "2024-11-11 10:19:10.221302+00",
    "idea_id": "1731324408260"
  },
  {
    "category_id": "1e2c89c0-6948-49bb-bc86-b49374282402",
    "category_name": "Jaipur",
    "category_description": "Discover Jaipur’s vibrant palaces, historic forts, and colorful markets—an ideal gateway to Rajasthan’s royal heritage.",
    "merchant_id": "suryoday",
    "is_active": false,
    "image_vertical_url": "https://communications.saathi.money/suryoday_ideas_bucket/jaipur.jpg",
    "image_horizontal_url": "https://communications.saathi.money/suryoday_ideas_bucket/jaipur.jpg",
    "createdAt": "2024-11-11 10:19:10.2518+00",
    "updatedAt": "2024-11-11 10:19:10.2518+00",
    "idea_id": "1731324408260"
  },
  {
    "category_id": "da2c5960-29a8-4b4e-8645-d91059a95e95",
    "category_name": "Rameshwaram",
    "category_description": "Rameshwaram, a place of mythology and devotion, offers stunning temples and tranquil beaches on the island of Pamban.",
    "merchant_id": "suryoday",
    "is_active": false,
    "image_vertical_url": "https://communications.saathi.money/suryoday_ideas_bucket/rameshwaram.png",
    "image_horizontal_url": "https://communications.saathi.money/suryoday_ideas_bucket/rameshwaram.png",
    "createdAt": "2024-11-11 10:19:10.265336+00",
    "updatedAt": "2024-11-11 10:19:10.265336+00",
    "idea_id": "1731324408260"
  },
  {
    "category_id": "ebf686af-fb36-4eac-9ada-6be0171c539d",
    "category_name": "Golden Temple",
    "category_description": "Experience the spiritual serenity of the Golden Temple, where the shimmering lake and welcoming community make it a must-visit in Amritsar.",
    "merchant_id": "suryoday",
    "is_active": false,
    "image_vertical_url": "https://communications.saathi.money/suryoday_ideas_bucket/golden_temple.jpg",
    "image_horizontal_url": "https://communications.saathi.money/suryoday_ideas_bucket/golden_temple.jpg",
    "createdAt": "2024-11-11 10:19:10.189628+00",
    "updatedAt": "2024-11-11 10:19:10.189628+00",
    "idea_id": "1731324408260"
  }
]
// export const ideaCategories: IdeaCategory[] = [
//   {
//     category_id: 'aBcDeFgHiJ',
//     category_name: 'Travel',
//     category_description:
//       'Embark on journeys that create memories, broaden horizons, and illuminate the soul. Travel, where adventure meets self-discovery.',
//     idea_id: '',
//     merchant_id: '',
//     is_active: true,
//     image_vertical_url:
//       'https://images.pexels.com/photos/731217/pexels-photo-731217.jpeg?auto=compress&cs=tinysrgb&w=800',
//     image_horizontal_url:
//       'https://images.pexels.com/photos/2087391/pexels-photo-2087391.jpeg?auto=compress&cs=tinysrgb&w=800',
//     createdAt: '',
//     updatedAt: '',
//   },
//   {
//     category_id: 'kLmNoPqRsT',
//     category_name: 'Home',
//     category_description:
//       'Transform your living space into a haven. Home is where dreams flourish, memories bloom, and your unique story unfolds.',
//     idea_id: '',
//     merchant_id: '',
//     is_active: true,
//     image_vertical_url:
//       'https://images.pexels.com/photos/2102587/pexels-photo-2102587.jpeg?auto=compress&cs=tinysrgb&w=800',
//     image_horizontal_url:
//       'https://images.pexels.com/photos/106399/pexels-photo-106399.jpeg?auto=compress&cs=tinysrgb&w=800',
//     createdAt: '',
//     updatedAt: '',
//   },
//   {
//     category_id: 'uVwXyZaBcD',
//     category_name: 'Auto',
//     category_description:
//       'Ride into freedom and exhilaration. Your journey is a canvas; paint it with the wheels of your dreams.',
//     idea_id: '',
//     merchant_id: '',
//     is_active: true,
//     image_vertical_url:
//       'https://images.pexels.com/photos/1545743/pexels-photo-1545743.jpeg?auto=compress&cs=tinysrgb&w=800',
//     image_horizontal_url:
//       'https://images.pexels.com/photos/164634/pexels-photo-164634.jpeg?auto=compress&cs=tinysrgb&w=800',
//     createdAt: '',
//     updatedAt: '',
//   },
//   {
//     category_id: 'EfGhIjKlMn',
//     category_name: 'Fitness',
//     category_description:
//       'Sculpt a healthier, vibrant you. Fitness is the key to unlocking energy, resilience, and a life well-lived.',
//     idea_id: '',
//     merchant_id: '',
//     is_active: true,
//     image_vertical_url:
//       'https://images.pexels.com/photos/3225889/pexels-photo-3225889.jpeg?auto=compress&cs=tinysrgb&w=800',
//     image_horizontal_url:
//       'https://images.pexels.com/photos/2294361/pexels-photo-2294361.jpeg?auto=compress&cs=tinysrgb&w=800',
//     createdAt: '',
//     updatedAt: '',
//   },
//   {
//     category_id: 'oPqRsTuVwX',
//     category_name: 'Family',
//     category_description:
//       'Build bonds that withstand time. Family is a tapestry woven with love, laughter, and shared dreams.',
//     idea_id: '',
//     merchant_id: '',
//     is_active: true,
//     image_vertical_url:
//       'https://images.pexels.com/photos/1456951/pexels-photo-1456951.jpeg?auto=compress&cs=tinysrgb&w=800',
//     image_horizontal_url:
//       'https://images.pexels.com/photos/4148842/pexels-photo-4148842.jpeg?auto=compress&cs=tinysrgb&w=800',
//     createdAt: '',
//     updatedAt: '',
//   },
//   {
//     category_id: 'yZaBcDeFgH',
//     category_name: 'Education',
//     category_description:
//       'Ignite your mind, shape your future. Education is the compass guiding you through the unexplored realms of knowledge.',
//     idea_id: '',
//     merchant_id: '',
//     is_active: true,
//     image_vertical_url:
//       'https://images.pexels.com/photos/433333/pexels-photo-433333.jpeg?auto=compress&cs=tinysrgb&w=800',
//     image_horizontal_url:
//       'https://images.pexels.com/photos/256455/pexels-photo-256455.jpeg?auto=compress&cs=tinysrgb&w=800',
//     createdAt: '',
//     updatedAt: '',
//   },
//   {
//     category_id: 'iJkLmNoPqR',
//     category_name: 'Entertainment',
//     category_description:
//       'Craft moments that resonate. Entertainment is the canvas where your stories and emotions come to life.',
//     idea_id: '',
//     merchant_id: '',
//     is_active: true,
//     image_vertical_url:
//       'https://images.pexels.com/photos/2927080/pexels-photo-2927080.jpeg?auto=compress&cs=tinysrgb&w=800',
//     image_horizontal_url:
//       'https://images.pexels.com/photos/1763075/pexels-photo-1763075.jpeg?auto=compress&cs=tinysrgb&w=800',
//     createdAt: '',
//     updatedAt: '',
//   },
//   {
//     category_id: 'sTuVwXyZaB',
//     category_name: 'Entrepreneurship',
//     category_description:
//       'Manifest ideas into reality. Entrepreneurship is the journey where passion, creativity, and perseverance converge.',
//     idea_id: '',
//     merchant_id: '',
//     is_active: true,
//     image_vertical_url:
//       'https://images.pexels.com/photos/6592547/pexels-photo-6592547.jpeg?auto=compress&cs=tinysrgb&w=800',
//     image_horizontal_url:
//       'https://images.pexels.com/photos/5922329/pexels-photo-5922329.jpeg?auto=compress&cs=tinysrgb&w=800',
//     createdAt: '',
//     updatedAt: '',
//   },
// ];


